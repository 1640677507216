import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

import { useIntersectionObserver, useIsDesktop } from '../lib/hooks';

import Word, { getWords } from './Word';

const { transitions } = theme;

const StyledTitle = styled.h2`
  font-size: ${theme.typography.font800};
  letter-spacing: ${1 / theme.typography.baseFontSize}rem;
  line-height: 1.3;
  overflow: hidden;

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${theme.typography.font700};
    letter-spacing: ${0.5 / theme.typography.baseFontSize}rem;
    line-height: 1.2;
  }

  &.large {
    font-size: ${theme.typography.font900};
    line-height: 1;

    @media (max-width: ${theme.breakpoints.small}) {
      font-size: ${theme.typography.font700};
    }
  }

  &.small {
    font-size: ${theme.typography.font600};
    line-height: 1.5;

    @media (max-width: ${theme.breakpoints.small}) {
      font-size: ${theme.typography.font400};
      line-height: 1.4;
    }
  }
`;

const AnimatedWord = styled(Word)`
  opacity: 0;
  transition-delay: ${({ $delay }) => `${$delay}ms`};
  transition-duration: ${({ $alt }) =>
    $alt ? transitions.duration : transitions.durationMedium};
  transition-timing-function: ${transitions.easingAlt};

  @media (max-width: ${theme.breakpoints.small}) {
    transition-delay: 0s;
  }

  .active > & {
    opacity: 1;
  }
`;

const Title = ({
  children,
  size = 'medium',
  className,
  onUpdateDuration = () => {},
  ...rest
}) => {
  const [words, setWords] = useState([]);
  const isDesktop = useIsDesktop();
  const ref = useRef();
  const active = useIntersectionObserver(ref);
  const altAnim = useMemo(() => isDesktop && size === 'large', [
    isDesktop,
    size
  ]);
  const delay = useMemo(() => (altAnim ? 30 : 150), [altAnim]);

  useEffect(() => {
    setWords(altAnim ? children.split('') : getWords(children, isDesktop));
  }, [altAnim, children, isDesktop]);

  return (
    <StyledTitle
      ref={ref}
      className={`${className ? className : ''} ${active && 'active'} ${size}`}
      {...rest}
    >
      {words.map((char, i) => (
        <AnimatedWord key={i} $delay={i * delay} $alt={altAnim}>
          {char}
        </AnimatedWord>
      ))}
    </StyledTitle>
  );
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onUpdateDuration: PropTypes.func,
  size: PropTypes.string
};

export default Title;
