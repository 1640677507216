import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useStateContext } from '../lib/context';

import theme from '../theme';

import Label from './Label';
import LinkExternal from './LinkExternal';

import { ReactComponent as Facebook } from '../assets/facebook.svg';
import { ReactComponent as Instagram } from '../assets/instagram.svg';

const StyledSection = styled.nav`
  align-items: center;
  color: #fff;
  display: grid;
  row-gap: ${theme.sizing.scale200};
  justify-items: center;
`;

const StyledItems = styled(StyledSection)`
  display: inline-grid;
  column-gap: ${theme.sizing.scale100};
  grid-auto-flow: column;
`;

const StyledLink = styled(LinkExternal)`
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: inline-flex;
  height: 25px;
  justify-content: center;
  transition: border ${theme.transitions.duration} ${theme.transitions.easing};
  width: 25px;

  @media (max-width: ${theme.breakpoints.small}) {
    height: 35px;
    width: 35px;
  }

  &:hover {
    border-color: currentcolor;
  }
`;

const StyledIcon = styled.svg`
  max-height: 13px;
  max-width: 16px;
  width: 100%;
`;

const SocialNav = ({ showLabel }) => {
  const [
    {
      settings: { facebook, instagram, socialLabel },
    },
  ] = useStateContext();

  return (
    <StyledSection>
      {showLabel && (
        <div>
          <Label small as="div">
            {socialLabel}
          </Label>
          <Label small as="div">
            @castropasteisdenata
          </Label>
        </div>
      )}
      <StyledItems as="div">
        {instagram && (
          <StyledLink href={instagram}>
            <StyledIcon as={Instagram} />
          </StyledLink>
        )}
        {facebook && (
          <StyledLink href={facebook}>
            <StyledIcon as={Facebook} />
          </StyledLink>
        )}
      </StyledItems>
    </StyledSection>
  );
};

SocialNav.propTypes = {
  showLabel: PropTypes.bool
};

export default memo(SocialNav);
