import React from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import ApolloWrap from './lib/apollo';
import App from './App';

const rootElement = document.getElementById('root');

const AppWrap = () => (
  <ApolloWrap>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloWrap>
);

if (rootElement.hasChildNodes()) {
  hydrate(<AppWrap />, rootElement);
} else {
  render(<AppWrap />, rootElement);
}
