import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import theme from '../theme';

import { useStateContext } from '../lib/context';

import Logo from '../components/Logo';

const ANIMATION_DURATION = 1.5; // in s

const FADE_IN = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const FADE_OUT = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const LOADING_ANIM = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  50% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

const LoaderWrap = styled.div`
  align-items: center;
  animation-name: ${FADE_IN};
  animation-duration: ${theme.transitions.duration};
  animation-fill-mode: forwards;
  animation-timing-function: ${theme.transitions.easingAlt};
  background-color: ${theme.colors.default};
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;

  &.hide {
    animation-name: ${FADE_OUT};
  }
`;

const Loader = styled(Logo)`
  max-width: 100%;
  opacity: 0;
  width: 300px;

  &.animate {
    animation-name: ${LOADING_ANIM};
    animation-duration: ${ANIMATION_DURATION}s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
`;

export default () => {
  const wrapRef = useRef();
  const loaderRef = useRef();
  const [appear, setAppear] = useState(true);
  const [animated, setAnimated] = useState(false);
  const [canHide, setCanHide] = useState(false);
  const [{ loaded }, dispatch] = useStateContext();

  useEffect(() => {
    window.addEventListener('load', () => {
      setCanHide(true);
    });
  }, []);

  return !loaded ? (
    <LoaderWrap
      ref={wrapRef}
      className={appear ? '' : 'hide'}
      onAnimationEnd={({ target }) => {
        if (target === wrapRef.current) {
          setAnimated(true);

          if (!appear) {
            dispatch({
              type: 'changeStatus',
              loaded: true
            });
          }
        }
      }}
    >
      <Loader
        className={animated && appear ? 'animate' : ''}
        ref={loaderRef}
        onAnimationIteration={({ target, elapsedTime }) => {
          if (
            canHide &&
            target === loaderRef.current &&
            elapsedTime >= ANIMATION_DURATION
          ) {
            setAppear(false);
          }
        }}
      />
    </LoaderWrap>
  ) : null;
};
