import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import theme from '../theme';

import { PAGE_BY_SLUG } from '../gql/page';
import { SETTINGS_GLOBAL } from '../gql/settings';
import { useStateContext } from '../lib/context';
import { useLocale } from '../lib/hooks';
import { getPageType, isHome, removeProtocol } from '../lib/page';

import Error from './Error';
import Article from '../components/Article';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Highlight from '../components/Highlight';
import Intro from '../components/Intro';
import List from '../components/List';
import Location from '../components/Location';
import Quote from '../components/Quote';
import Slider from '../components/Slider';

const Main = styled.main`
  padding-top: ${theme.sizing.scale500};

  @media (max-width: ${theme.breakpoints.small}) {
    padding-top: ${theme.sizing.scale600};
  }

  &.hidden {
    opacity: 0;
  }
`;

export default ({ onLoaded = () => {} }) => {
  const { slug } = useParams();
  const [{ loaded, settings }, dispatch] = useStateContext();
  const locale = useLocale();
  const pageType = getPageType(slug);

  const {
    loading: loadingSettings,
    data: dataSettings,
    error: errorSettings,
  } = useQuery(SETTINGS_GLOBAL, {
    fetchPolicy: 'cache-first',
    variables: {
      locale,
    },
  });

  const { data, loading: loadingData, error } = useQuery(PAGE_BY_SLUG, {
    skip: !dataSettings || Object.keys(dataSettings).length === 0,
    fetchPolicy: 'cache-first',
    variables: {
      locale,
      slug,
      pageType,
    },
    onCompleted: onLoaded,
  });

  useEffect(() => {
    if (dataSettings) {
      const { Settings } = dataSettings;

      if (Settings && Settings !== settings) {
        dispatch({
          type: 'changeSettings',
          newSettings: Settings,
        });
      }
    }
  }, [dataSettings, dispatch, settings]);

  if (error || errorSettings) {
    return <Error />;
  }

  if (!loadingSettings && !loadingData && data && !data.allPage.edges.length) {
    return <Redirect to="/404" />;
  }

  const details = data ? data.allPage.edges[0].node : {};
  const { title, content, description, image } = details;
  const pageTitle = data
    ? isHome(pageType)
      ? settings.siteName
      : `${title} | ${settings.siteName}`
    : process.env.REACT_APP_SITE_NAME;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:image" content={removeProtocol(image)} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <Header type={isHome(pageType) ? 'full' : 'simple'} />
      {content && (
        <>
          <Main className={loaded ? '' : 'hidden'}>
            {content.map((section, i) => {
              switch (section.__typename) {
                case 'Hero':
                  return <Hero key={i} order={i} {...section} />;
                case 'Slider':
                  return <Slider key={i} {...section} />;
                case 'Intro':
                  return <Intro key={i} {...section} type={section.type[0]} />;
                case 'Article':
                  return (
                    <Article key={i} {...section} layout={section.layout[0]} />
                  );
                case 'Location':
                  return <Location key={i} {...section} />;
                case 'Quote':
                  return <Quote key={i} {...section} />;
                case 'Highlight':
                  return <Highlight key={i} {...section} />;
                case 'List':
                  return <List key={i} {...section} />;
                default:
                  return null;
              }
            })}
          </Main>
          <Footer />
        </>
      )}
    </>
  );
};
