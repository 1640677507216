import React, { forwardRef, memo } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';

import { getLocaleDefault } from '../lib/locale';
import { isHome } from '../lib/page';

/*
    URL structure:

    /slug - default locale
    /locale/slug - other locales
*/

const defaultLocale = getLocaleDefault();

const LinkLocale = (
  { className, slug, locale, children, pageType, ...rest },
  ref
) => {
  const params = useParams();

  const destinationLocale = locale || params.locale; //if no locale point to current page locale
  const destinationUrl = slug || params.slug || ''; //if no slug point to current page slug else empty string

  const paramSlug = !isHome(pageType) ? `/${destinationUrl}` : '/';
  const paramLocale =
    destinationLocale && destinationLocale !== defaultLocale
      ? `/${destinationLocale}`
      : '';
  const url = `${paramLocale}${paramSlug}`;

  const match = useRouteMatch({
    path: url,
    exact: true
  });

  return (
    <a
      ref={ref}
      href={url}
      className={match ? `active ${className}` : className}
      {...rest}
    >
      {children}
    </a>
  );
};

export default memo(forwardRef(LinkLocale));
