import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';

import { useLocale } from '../lib/hooks';
import { pageTypes } from '../lib/page';
import { MENU } from '../gql/menu';

import theme from '../theme';

import LocaleSwitch from './LocaleSwitch';
import SocialNav from './SocialNav';
import Burger from './Burger';
import Link from './Link';
import LinkLocale from './LinkLocale';
import Logo from '../components/Logo';

const Wrap = styled.section`
  align-content: center;
  background-color: ${theme.colors.default};
  display: grid;
  font-family: ${theme.typography.families.title};
  height: 100%;
  left: 0;
  justify-items: center;
  max-width: 525px;
  padding: ${theme.sizing.scale500};
  position: fixed;
  row-gap: 80px;
  width: 100%;
  top: 0;
  transition: transform ${theme.transitions.duration}
    ${theme.transitions.easing};
  z-index: 110;

  @media (max-height: ${theme.breakpoints.small}) {
    align-content: space-between;
    padding: ${theme.sizing.scale300};
    row-gap: ${theme.sizing.scale300};
    transition-duration: ${theme.transitions.durationLong};
  }

  &::after {
    background-color: rgba(9, 18, 32, 0.74);
    content: '';
    display: block;
    height: 100%;
    left: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity ${theme.transitions.duration}
      ${theme.transitions.easing};
    width: 100vw;
  }

  &[aria-hidden='true'] {
    transform: translateX(-100%);

    &::after {
      opacity: 0;
    }
  }
`;

const StyledLogoLink = styled(LinkLocale)`
  justify-self: center;

  @media (max-width: ${theme.breakpoints.xsmall}) {
    display: none;
  }
`;

const StyledLogo = styled(Logo)`
  width: 200px;

  .label {
    display: none;
  }
`;

const List = styled.nav`
  align-content: center;
  font-size: ${theme.typography.font500};
  font-weight: ${theme.typography.weights.bold};
  display: grid;
  justify-items: center;
  row-gap: ${theme.sizing.scale200};
  text-transform: uppercase;

  @media (max-width: ${theme.breakpoints.xsmall}) {
    row-gap: ${theme.sizing.scale100};
  }
`;

const Label = styled.span`
  color: ${theme.colors.primary};
  font-size: ${theme.typography.font100};
  letter-spacing: ${0.83 / theme.typography.baseFontSize}rem;
  user-select: none;
`;

const StyledLink = styled(Link)`
  color: #fff;
  position: relative;
  letter-spacing: ${1.65 / theme.typography.baseFontSize}rem;
  transition: color ${theme.transitions.duration} ${theme.transitions.easing};

  &.active {
    color: ${theme.colors.quaternary};
  }

  &:hover {
    color: ${theme.colors.primary};
  }

  &:first-child {
    margin-top: 0;

    @media (min-width: ${theme.breakpoints.small}) {
      font: ${theme.typography.font300};
    }
  }
`;

const Menu = ({ open, onClose }) => {
  const locale = useLocale();
  const { loading, data, error } = useQuery(MENU, {
    variables: {
      locale,
      placement: 'Main'
    }
  });

  if (loading || error || !data || (data && !data.Menu)) {
    return null;
  }

  const { items } = data.Menu;

  return (
    <Wrap aria-hidden={!open}>
      <Burger onClick={onClose} open />
      <StyledLogoLink pageType={pageTypes.home}>
        <StyledLogo />
      </StyledLogoLink>
      {items && (
        <List>
          <Label>menu</Label>
          {items.map((item, i) => (
            <StyledLink key={i} {...item} />
          ))}
        </List>
      )}
      <LocaleSwitch />
      <SocialNav />
    </Wrap>
  );
};

Menu.propTypes = {
  areas: PropTypes.array,
  canAnim: PropTypes.bool
};

export default memo(Menu);
