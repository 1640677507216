import gql from 'graphql-tag';

import fragments from './fragments';

export const MENU = gql`
  query menu($locale: String) {
    Menu(locale: $locale) {
      id
      title
      items {
        ...ExternalLink
        ...InternalLink
        ...UnknownItem
      }
    }
  }
  ${fragments.internalLink}
  ${fragments.externalLink}
  ${fragments.unknown}
`;
