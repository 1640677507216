import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

import theme from '../theme';

import { ReactComponent as LogoMonogram } from '../assets/logo-monogram.svg';

import { useAnimationScroll } from '../lib/hooks';
import { pageTypes } from '../lib/page';
import { useStateContext } from '../lib/context';

import Burger from '../components/Burger';
import LinkLocale from './LinkLocale';
import Menu from '../components/Menu';
import Logo from '../components/Logo';

const HEADER_ANIM = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Section = styled.header`
  align-items: center;
  animation-duration: ${theme.transitions.duration};
  animation-fill-mode: forwards;
  animation-name: ${({ $loaded }) => ($loaded ? HEADER_ANIM : 'none')};
  animation-timing-function: ${theme.transitions.easingAlt};
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  left: 0;
  opacity: 0;
  padding: ${theme.sizing.scale400} ${theme.sizing.scale500};
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;

  &.scrolled {
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: ${theme.sizing.scale100} ${theme.sizing.scale500};
    position: fixed;

    @media (max-width: ${theme.breakpoints.small}) {
      padding-left: ${theme.sizing.scale300};
      padding-right: ${theme.sizing.scale300};
    }
  }

  @media (max-width: ${theme.breakpoints.small}) {
    padding: ${theme.sizing.scale200} ${theme.sizing.scale300};
    transition: none;
  }
`;

const HomeLink = styled(LinkLocale)`
  align-items: center;
  display: inline-grid;
  justify-self: center;
  justify-items: center;
`;

const StyledLogo = styled(Logo)`
  grid-column: 1;
  grid-row: 1;
  opacity: 1;
  width: 130px;

  .label {
    display: none;
  }

  @media (max-width: ${theme.breakpoints.small}) {
    max-width: 130px;
    opacity: ${({ type }) => (type === 'full' ? 1 : 0)};
    width: 100%;
  }

  ${Section}.scrolled & {
    opacity: 0;
  }
`;

const StyledMonogram = styled(LogoMonogram)`
  grid-column: 1;
  grid-row: 1;
  opacity: 0;

  @media (max-width: ${theme.breakpoints.small}) {
    opacity: ${({ type }) => (type === 'full' ? 0 : 1)};
  }

  ${Section}.scrolled & {
    opacity: 1;
  }
`;

const Header = ({ type }) => {
  const [{ loaded }] = useStateContext();
  const previousScroll = useRef(0);
  const scrolled = useAnimationScroll();
  const [showMenu, setShowMenu] = useState(false);
  const isActive = useCallback(() => {
    const prev = previousScroll.current;

    previousScroll.current = scrolled;

    return scrolled < prev && scrolled > 100;
  }, [scrolled]);

  return (
    <>
      <Section className={isActive() ? 'scrolled' : ''} $loaded={loaded}>
        <Burger onClick={() => setShowMenu(!showMenu)} open={showMenu} />
        <HomeLink pageType={pageTypes.home}>
          <StyledLogo type={type} />
          <StyledMonogram type={type} />
        </HomeLink>
      </Section>
      <Menu open={showMenu} onClose={() => setShowMenu(false)} />
    </>
  );
};

Header.propTypes = {
  type: PropTypes.string.isRequired
};

export default memo(Header);
