import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import 'intersection-observer';
import { useParams } from 'react-router-dom';
import showdown from 'showdown';

import { getCurrLocale } from '../lib/locale';
import { useStateContext } from '../lib/context';

import theme from '../theme';

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(false);

  useLayoutEffect(() => {
    function getWidth() {
      setIsDesktop(window.innerWidth > parseInt(theme.breakpoints.small));
    }

    window.addEventListener('resize', getWidth, true);

    setTimeout(getWidth, 250);

    return () => window.removeEventListener('resize', getWidth, true);
  }, []);

  return isDesktop;
};

export const useLocale = () => {
  const { locale } = useParams();

  return getCurrLocale(locale);
};

export const useIntersectionObserver = (
  ref,
  threshold = 0.25,
  awaitLoad = true
) => {
  const [{ loaded }] = useStateContext();
  const [active, setActive] = useState(false);
  const observer = useRef();

  useEffect(() => {
    const item = ref.current;

    const unobserve = () => item && observer.current.disconnect();

    const handleIntersection = ([entry]) => {
      const insersectingY =
        entry.boundingClientRect.y +
          entry.boundingClientRect.height * threshold <=
        window.innerHeight; // check if intersection is bigger than threshold in y axis

      if (insersectingY) {
        setActive(true);
        unobserve();
      }
    };

    if (observer.current) unobserve();

    observer.current = new IntersectionObserver(handleIntersection, {
      threshold: threshold,
    });

    if (item) {
      setTimeout(() => observer.current.observe(item), 100);
    }

    return () => unobserve();
  }, [ref, threshold]);

  if (awaitLoad) {
    return active && loaded;
  }

  return active;
};

export const useAnimationFrame = (callback) => {
  const requestRef = useRef();
  const previousTimeRef = useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      const deltaTime = time - previousTimeRef.current;
      callback(deltaTime);
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestRef.current);
    // eslint-disable-next-line
  }, []);
};

export const useAnimationScroll = (ref) => {
  const [scrolled, setScrolled] = useState(0);

  useAnimationFrame(() => {
    const scrolled =
      ref && ref.current
        ? ref.current.getBoundingClientRect().top
        : window.pageYOffset;

    setScrolled(scrolled);
  });

  return scrolled;
};

export const useMarkdownParser = (markdown, inline = false) => {
  const [html, setHtml] = useState(markdown);

  useEffect(() => {
    showdown.extension('exclude-paragraphs', () => [
      {
        type: 'output',
        filter: function (text) {
          // remove paragraphs
          text = text.replace(/<\/?p[^>]*>/g, '');

          return text;
        },
      },
    ]);

    const converter = new showdown.Converter({
      simpleLineBreaks: true,
      extensions: inline ? ['exclude-paragraphs'] : [],
    });

    setHtml(converter.makeHtml(markdown));
  }, [inline, markdown]);

  return html;
};
