const { REACT_APP_LOCALES, REACT_APP_LOCALE_DEFAULT } = process.env;

export const getCurrLocale = locale => {
  return locale || REACT_APP_LOCALE_DEFAULT;
};

export const getLocales = () => {
  return REACT_APP_LOCALES.split('|');
};

export const getLocalesString = () => {
  return REACT_APP_LOCALES;
};

export const getLocaleDefault = () => {
  return REACT_APP_LOCALE_DEFAULT;
};

export const getLocaleLabel = locale => {
  switch (locale) {
    case 'pt':
      return 'Português';
    case 'en':
      return 'English';
    case 'es':
      return 'Español';
    default:
      return '';
  }
};
