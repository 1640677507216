import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { useTransition, animated } from 'react-spring';

import { StateProvider } from './lib/context';
import { getLocalesString } from './lib/locale';

import theme, { fontFaces } from './theme';

import Error from './pages/Error';
import NotFound from './pages/NotFound';
import Page from './pages/Page';
import Loader from './components/Loader';

const fontFaceCss = fontFaces
  .map(
    ({ name, location, weight }) => `@font-face {
    font-family: '${name}';
    src: url(${location}) format("woff");
    font-weight: ${weight};
  }`
  )
  .join(' ');

const GlobalStyle = createGlobalStyle`
  ${reset}
  ${fontFaceCss}
  html {
    -webkit-overflow-scrolling: touch; /* lets it scroll lazy */
    -webkit-overflow-scrolling: auto; /* stop scrolling immediately */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${theme.typography.families.body};
    font-size: ${theme.typography.baseFontSize}px;
    font-weight: ${theme.typography.weights.light};
    scroll-behavior: smooth;
  }
  body[style] * {
    animation: none;
    transition: none;
  }
  #root {
    box-sizing: border-box;
    color: ${theme.colors.default};
    line-height: ${theme.typography.baseLineHeight};
    overflow-x: hidden;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  a {
    border: none;
    color: currentcolor;
    text-decoration: none;

    &:hover {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      outline: 0;
    }
  }
  button {
    appearance: none;
    border: none;
    color: currentcolor;
    cursor: pointer;
    font-family: inherit;
    padding: 0;

    &:hover {
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      -webkit-tap-highlight-color: transparent;
      outline: 0;
    }
  }
`;

const localesList = getLocalesString();

export default () => {
  const location = useLocation();
  const transitions = useTransition(location, location => location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    immediate: true
  });

  useEffect(() => {
    setTimeout(() => {
      document.body.removeAttribute('style');
      window.scrollTo(0, 0);
    }, 250);
  }, [location.pathname]);

  const initialState = {
    settings: [],
    showMenu: false,
    loaded: false
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeSettings':
        return {
          ...state,
          settings: action.newSettings
        };
      case 'toggleMenu':
        return {
          ...state,
          showMenu: action.open
        };
      case 'changeStatus':
        return {
          ...state,
          loaded: action.loaded
        };
      default:
        return state;
    }
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <GlobalStyle />
      {transitions.map(({ item, props, key }) => (
        <animated.div key={key} style={props}>
          <Switch location={item}>
            <Route path="/404" exact component={NotFound} />
            <Route path="/500" exact component={Error} />
            <Route
              path={`/:locale(${localesList})?/:slug?`}
              render={props => (
                <>
                  <Page {...props} />
                  <Loader />
                </>
              )}
            />
          </Switch>
        </animated.div>
      ))}
    </StateProvider>
  );
};
