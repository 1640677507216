import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import theme from '../theme';

import Button from '../components/Button';
import Paragraph from '../components/Paragraph';
import Title from '../components/Title';

const Main = styled.main`
  align-content: center;
  background-color: ${theme.colors.default};
  color: #fff;
  height: 100vh;
  display: grid;
  flex-direction: column;
  justify-items: center;
  padding: ${theme.sizing.scale400};
  row-gap: ${theme.sizing.scale300};
`;

export default () => {
  return (
    <Main>
      <Helmet>
        <title>404</title>
      </Helmet>
      <Title>Oops</Title>
      <Paragraph content="The item you are looking for isn't on the menu." />
      <Button as="a" href="/">
        go to the lobby
      </Button>
    </Main>
  );
};
