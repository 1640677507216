import React, { memo } from 'react';
import styled from 'styled-components';

import { pageTypes } from '../lib/page';
import { getLocales, getLocaleLabel } from '../lib/locale';
import { useLocale } from '../lib/hooks';

import theme from '../theme';

import LinkLocale from './LinkLocale';

const StyledSection = styled.nav`
  align-items: center;
  column-gap: ${theme.sizing.scale300};
  display: inline-grid;
  grid-auto-flow: column;
  pointer-events: all;
`;

const StyledLocale = styled(LinkLocale)`
  color: ${theme.colors.quinary};
  font-family: ${theme.typography.families.title};
  font-size: ${theme.typography.font100};
  font-weight: ${theme.typography.weights.bold};
  letter-spacing: ${0.83 / theme.typography.baseFontSize}rem;
  line-height: 1;
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  position: relative;
  text-transform: uppercase;
  transition: opacity ${theme.transitions.duration} ${theme.transitions.easing};

  &:hover {
    opacity: 1;
  }

  &::before {
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    content: '';
    display: ${({ active }) => (active ? 'block' : 'none')};
    height: 5px;
    position: absolute;
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-55%);
    width: 5px;
  }
`;

const localesArr = getLocales();

const LocaleSwitch = () => {
  const locale = useLocale();

  return (
    <StyledSection>
      {localesArr.map(item => (
        <StyledLocale
          locale={item}
          key={item}
          hrefLang={item}
          pageType={pageTypes.home}
          $active={locale === item}
        >
          {getLocaleLabel(item)}
        </StyledLocale>
      ))}
    </StyledSection>
  );
};

export default memo(LocaleSwitch);
