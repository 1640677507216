import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';

import { useIntersectionObserver, useMarkdownParser } from '../lib/hooks';

const StyledText = styled(animated.article)`
  color: ${({ $alt }) => ($alt ? 'inherit' : theme.colors.defaultFaded)};
  columns: ${({ $columns }) => $columns};
  column-gap: ${theme.sizing.scale300};
  max-width: ${({ $columns }) => ($columns > 1 ? 'auto' : '560px')};

  @media (max-width: ${theme.breakpoints.small}) {
    columns: 1;
  }

  p {
    break-inside: avoid;
    padding-bottom: ${theme.sizing.scale100};

    &:last-child {
      padding-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  strong {
    font-weight: ${theme.typography.weights.bold};
  }

  em {
    font-style: italic;
  }
`;

const { fadeIn } = theme.animations;

const Paragraph = ({
  alt = false,
  delay = 0,
  content,
  columns = 1,
  inline = false,
  ...rest
}) => {
  const __html = useMarkdownParser(content, inline);
  const ref = useRef();
  const active = useIntersectionObserver(ref, 0.35);
  const animContent = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.molasses,
    delay: window.innerWidth < parseInt(theme.breakpoints.small) ? 0 : delay,
  });

  return (
    <StyledText
      ref={ref}
      style={animContent}
      $alt={alt}
      $columns={columns}
      dangerouslySetInnerHTML={{ __html }}
      {...rest}
    />
  );
};

Paragraph.propTypes = {
  alt: PropTypes.bool,
  content: PropTypes.string.isRequired,
  columns: PropTypes.number,
  delay: PropTypes.number,
  inline: PropTypes.bool,
};

export default Paragraph;
