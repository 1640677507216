import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';
import { BUTTON_GRADIENT } from './Button';

const BORDER_WIDTH = '1px';
const BUTTON_SIZE = '40px';

export const Button = styled.button`
  align-items: center;
  background: transparent;
  border-radius: 50%;
  color: ${theme.colors.primary};
  display: inline-flex;
  height: ${BUTTON_SIZE};
  overflow: hidden;
  position: relative;
  transform-style: preserve-3d;
  width: ${BUTTON_SIZE};

  &::before,
  &::after {
    background: ${BUTTON_GRADIENT};
    border-radius: inherit;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all ${theme.transitions.duration} ${theme.transitions.easing};
    width: 100%;
  }

  &::after {
    /* start gradient border*/
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    padding: ${BORDER_WIDTH};
    /* end gradient border*/
    opacity: 1;
  }

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;

    &::before {
      opacity: 1;
    }
  }

  /* gradient mask doesn't work on safari */
  &[data-simple='true']::after {
    -webkit-mask-composite: initial;
    border: 1px solid ${theme.colors.primary};
    background: none;
  }
`;

const ButtonContent = styled.div`
  display: inline-flex;
  justify-content: center;
  transform: translateZ(10px);
  width: 100%;
`;

const ButtonCircle = ({ children, onClick, ...rest }) => (
  <Button
    data-simple={/^((?!chrome|android).)*safari/i.test(navigator.userAgent)}
    onClick={() => {
      onClick();
    }}
    {...rest}
  >
    <ButtonContent>{children}</ButtonContent>
  </Button>
);

ButtonCircle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  onClick: PropTypes.func.isRequired
};

export default memo(ButtonCircle);
