import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, config, useSpring } from 'react-spring';

import { useIntersectionObserver } from '../lib/hooks';

import theme from '../theme';

import Monogram from './Monogram';
import Wrap from './Wrap';
import { WordGroup } from './Word';

const LAYOUTS = {
  default: 'Default',
  highlight: 'Highlight'
};

export const IntroWrap = styled(animated(Wrap))`
  align-content: center;
  display: grid;
  font-size: ${40 / theme.typography.baseFontSize}rem;
  justify-items: center;
  line-height: 1.23;
  position: relative;
  row-gap: ${theme.sizing.scale400};
  text-align: center;

  &::before {
    background-color: ${theme.colors.tertiary};
    content: '';
    display: ${({ $alt }) => ($alt ? 'block' : 'none')};
    height: 100%;
    position: absolute;
    width: 100vw;
    z-index: -1;
  }

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${({ $alt }) =>
      $alt ? theme.typography.font600 : theme.typography.font400};
    line-height: 1.4;
    padding-bottom: ${({ $alt }) =>
      $alt ? theme.sizing.scale600 : theme.sizing.scale400};
    padding-top: ${({ $alt }) =>
      $alt ? theme.sizing.scale600 : theme.sizing.scale400};
    row-gap: ${theme.sizing.scale200};
  }
`;

const { fadeUp } = theme.animations;

const Intro = ({ type, text }) => {
  const alt = type === LAYOUTS.highlight;
  const ref = useRef();
  const active = useIntersectionObserver(ref, 0.5);
  const anim = useSpring({
    from: fadeUp.from,
    to: active ? fadeUp.to : fadeUp.from,
    config: config.molasses
  });

  return (
    <IntroWrap ref={ref} style={anim} $alt={alt} large spacer>
      <p>
        <WordGroup active={active} content={text} />
      </p>
      {alt && <Monogram />}
    </IntroWrap>
  );
};

Intro.propTypes = {
  type: PropTypes.oneOf(Object.values(LAYOUTS)).isRequired,
  text: PropTypes.string
};

export default memo(Intro);
