import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, config, useSpring } from 'react-spring';

import { useIntersectionObserver } from '../lib/hooks';

import theme from '../theme';

import Label from './Label';
import Wrap from './Wrap';
import LinkExternal from './LinkExternal';

import { ReactComponent as BoltFood } from '../assets/bolt-food.svg';
import { ReactComponent as Glovo } from '../assets/glovo.svg';
import { ReactComponent as Tripadvisor } from '../assets/tripadvisor.svg';
import { ReactComponent as UberEats } from '../assets/uber-eats.svg';
import { ReactComponent as Zomato } from '../assets/zomato.svg';

const StyledSection = styled.nav`
  align-items: center;
  color: #fff;
  display: grid;
  row-gap: ${theme.sizing.scale200};
  justify-items: center;
`;

const StyledItems = styled(StyledSection)`
  display: inline-grid;
  column-gap: ${theme.sizing.scale100};
  grid-auto-flow: column;
`;

const StyledLink = styled(LinkExternal)`
  background-color: ${theme.colors.primary};
  align-items: center;
  border: 1px solid ${theme.colors.primary};
  display: inline-flex;
  height: 25px;
  justify-content: center;
  transition: border ${theme.transitions.duration} ${theme.transitions.easing};
  width: 25px;

  @media (max-width: ${theme.breakpoints.small}) {
    height: 35px;
    width: 35px;
  }

  &:hover {
    border-color: currentcolor;
  }
`;

const StyledIcon = styled.svg`
  max-height: 13px;
  max-width: 16px;
  width: 100%;
`;

const Section = styled(animated(Wrap))`
  align-items: center;
  display: grid;
  justify-items: center;
  row-gap: ${theme.sizing.scale200};
  text-align: center;

  @media (max-width: ${theme.breakpoints.small}) {
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: ${theme.sizing.scale700};

    @media (max-width: ${theme.breakpoints.small}) {
      padding-top: ${theme.sizing.scale500};
    }
  }
`;

const Title = styled.h2`
  color: #fff;
  font-size: ${180 / theme.typography.baseFontSize}rem;
  grid-column: 1;
  grid-row: 2;
  line-height: 1;
  opacity: 0.5;
  text-shadow: -1px -1px 0 ${theme.colors.primary},
    1px -1px 0 ${theme.colors.primary}, -1px 1px 0 ${theme.colors.primary},
    1px 1px 0 ${theme.colors.primary};

  @media (max-width: ${theme.breakpoints.small}) {
    color: ${theme.colors.primary};
    font-size: ${40 / theme.typography.baseFontSize}rem;
    grid-row: 1;
    opacity: 1;
    text-shadow: none;
  }
`;

const StyledLabel = styled(Label)`
  @media (max-width: ${theme.breakpoints.small}) {
    display: none;
  }
`;

const SubTitle = styled.h3`
  font-size: ${32 / theme.typography.baseFontSize}rem;
  grid-column: 1;
  grid-row: 2;
  z-index: 1;

  @media (max-width: ${theme.breakpoints.small}) {
    display: none;
  }
`;

const Address = styled.a`
  color: ${theme.colors.defaultFaded};
`;

const Text = styled.p`
  font-size: ${theme.typography.font600};

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: 1rem;
  }
`;

const { fadeUp } = theme.animations;

const links = {
  lisboa: {
    tripadvisor: 'https://www.tripadvisor.pt/Restaurant_Review-g189158-d23808694-Reviews-Castro_Atelier_De_Pasteis_De_Nata-Lisbon_Lisbon_District_Central_Portugal.html',
    uberEats: 'https://www.ubereats.com/pt/store/castro-garrett-zona-do-chiado/kOi1v7bfWZSv9CI21q__Rw?diningMode=DELIVERY',
    zomato: 'https://www.zomatoportugal.com/r/20069560/castro-atelier-de-pasteis-de-nata-chiado',
  },
  porto: {
    tripadvisor: 'https://www.tripadvisor.pt/Restaurant_Review-g189180-d19390528-Reviews-Castro_Atelier_de_Pasteis_de_Nata-Porto_Porto_District_Northern_Portugal.html',
    uberEats: 'https://www.ubereats.com/pt/store/castro-mouzinho-da-silveira/V4EK6RunRGqbxFOdhG0Q0Q?diningMode=DELIVERY',
    zomato: 'https://www.zomatoportugal.com/r/19258965/castro-atelier-de-pasteis-de-nata-baixa',
  },
}

const Location = ({ address, label, subTitle, text, title }) => {
  const ref = useRef();
  const active = useIntersectionObserver(ref);
  const anim = useSpring({
    from: fadeUp.from,
    to: active ? fadeUp.to : fadeUp.from,
    config: config.molasses
  });
  const location = title.toLowerCase();
  const addressSeparator = ' | ';

  if (address && address.includes(addressSeparator)) {
    address = address.split(addressSeparator);
  } else {
    address = [address];
  }

  return (
    <Section large ref={ref} style={anim}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {address.map(a => (
          <Address
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(a)}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {a}
          </Address>
        )
      )}
      <Text>{text}</Text>
      <StyledItems as="div">
        <StyledLink href={links[location].zomato}>
          <StyledIcon as={Zomato} />
        </StyledLink>
        <StyledLink href={links[location].tripadvisor}>
          <StyledIcon as={Tripadvisor} />
        </StyledLink>
        <StyledLink href={links[location].uberEats}>
          <StyledIcon as={UberEats} />
        </StyledLink>
      </StyledItems>
    </Section>
  );
};

Location.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  label: PropTypes.string,
  address: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default memo(Location);
