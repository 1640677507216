import React, { forwardRef, memo } from 'react';

import LinkLocale from '../components/LinkLocale';
import LinkExternal from '../components/LinkExternal';

const getDetails = ({ title, __typename, page, url }) => {
  switch (__typename) {
    case 'PageLink':
      return {
        ...page,
        title: title || page.title,
        component: LinkLocale
      };
    case 'FileLink': {
      return {
        title: title,
        href: url,
        component: LinkExternal
      };
    }
    default:
      return {
        title: title,
        href: url,
        component: LinkExternal
      };
  }
};

const Link = ({ className, onClick = () => {}, ...rest }, ref) => {
  const { title, component: Component, ...details } = getDetails(rest);

  return (
    <Component className={className} onClick={onClick} ref={ref} {...details}>
      {title}
    </Component>
  );
};

export default memo(forwardRef(Link));
