import styled from 'styled-components';

import theme from '../theme';

export default styled.section`
  margin: 0 auto;
  max-width: ${({ large }) => (large ? '930px' : '720px')};
  padding-bottom: ${({ spacer }) =>
    spacer ? theme.sizing.scale700 : theme.sizing.scale400};
  padding-top: ${({ spacer }) =>
    spacer ? theme.sizing.scale700 : theme.sizing.scale400};
  padding-left: ${theme.sizing.scale300};
  padding-right: ${theme.sizing.scale300};
  width: 100%;

  @media (max-width: ${theme.breakpoints.small}) {
    padding-bottom: ${theme.sizing.scale500};
    padding-top: ${theme.sizing.scale500};
  }
`;
