import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, config, useSpring } from 'react-spring';

import { useIntersectionObserver } from '../lib/hooks';

import theme from '../theme';

import { ReactComponent as QuoteImage } from '../assets/quote.svg';

import Wrap from './Wrap';
import { WordGroup } from './Word';

export const QuoteWrap = styled(animated(Wrap))`
  align-content: center;
  display: grid;
  font-size: ${40 / theme.typography.baseFontSize}rem;
  justify-items: center;
  line-height: 1.23;
  row-gap: ${theme.sizing.scale400};
  text-align: center;

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${theme.typography.font600};
    row-gap: ${theme.sizing.scale200};
  }
`;

const { fadeUp } = theme.animations;

const Quote = ({ text }) => {
  const ref = useRef();
  const active = useIntersectionObserver(ref, 0.5);
  const anim = useSpring({
    from: fadeUp.from,
    to: active ? fadeUp.to : fadeUp.from,
    config: config.molasses
  });

  return (
    <QuoteWrap spacer ref={ref} style={anim}>
      <QuoteImage />
      <p>
        <WordGroup active={active} content={text} />
      </p>
      <QuoteImage />
    </QuoteWrap>
  );
};

Quote.propTypes = {
  text: PropTypes.string
};

export default Quote;
