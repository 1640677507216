import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

import { useIsDesktop } from '../lib/hooks';

import Figure from './Figure';
import Wrap from './Wrap';
import Label from './Label';
import Button from './Button';
import Link from './Link';
import Paragraph from './Paragraph';
import Slider from '../components/Slider';
import Title from './Title';

const Section = styled(Wrap)`
  align-items: end;
  color: #fff;
  column-gap: ${theme.sizing.scale300};
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${({ $offset }) => ($offset ? theme.sizing.scale500 : 0)};
  min-height: 400px;
  position: relative;
  row-gap: ${theme.sizing.scale500};

  @media (max-width: ${theme.breakpoints.small}) {
    background-color: ${theme.colors.default};
    grid-template-columns: 1fr;
    margin-top: 0;
  }

  &::before {
    background-color: ${theme.colors.default};
    content: '';
    display: block;
    height: ${({ $offset }) =>
      $offset ? `calc(100% - ${theme.sizing.scale700})` : '100%'};
    left: 50%;
    position: absolute;
    top: ${({ $offset }) => ($offset ? `-${theme.sizing.scale200}` : 0)};
    transform: translateX(-50%);
    width: 200vw;
    z-index: -1;

    @media (max-width: ${theme.breakpoints.small}) {
      display: none;
    }
  }
`;

const Grid = styled.div`
  align-content: center;
  display: grid;
  order: 2;
  row-gap: ${theme.sizing.scale200};
  justify-items: center;
  text-align: center;

  &:only-child {
    align-self: center;
    grid-column: 1 / -1;
    order: 1;
  }
`;

const StyledFigure = styled(Figure)`
  order: 1;
  transform: translate(7%, -55%);

  &:nth-of-type(2) {
    order: 3;
  }

  &:nth-of-type(3) {
    margin: 0;
    order: 4;
    transform: none;
  }
`;

const StyledSlider = styled(Slider)`
  max-width: 70vw;
  padding-bottom: ${theme.sizing.scale300};
  padding-top: ${theme.sizing.scale300};
`;

const Highlight = ({ button, images, label, title, text }) => {
  const isDesktop = useIsDesktop();
  const large = images && images.length > 0;
  const showSlider = images && images.length > 0 && !isDesktop;

  return (
    <Section large={large} $offset={large}>
      {isDesktop &&
        images &&
        images.map(({ image, alt }, i) => (
          <StyledFigure
            key={i}
            image={image}
            label={alt}
            animate={false}
            clip
          />
        ))}
      <Grid>
        {label && <Label>{label}</Label>}
        <Title size="medium">{title}</Title>
        {showSlider && <StyledSlider items={images} alt />}
        {text && <Paragraph content={text} alt />}
        {button &&
          button.map((item, i) => <Button key={i} as={Link} {...item} />)}
      </Grid>
    </Section>
  );
};

Highlight.propTypes = {
  button: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape(
      {
        image: PropTypes.string,
        alt: PropTypes.string
      }.isRequired
    )
  ),
  title: PropTypes.string.isRequired,
  text: PropTypes.string
};

export default memo(Highlight);
