import gql from 'graphql-tag';

import fragments from './fragments';

export const PAGE_BY_SLUG = gql`
  query pageBySlug($locale: String, $slug: String, $pageType: Page_PageType) {
    allPage(
      locale: $locale
      where: { slug: { eq: $slug }, pageType: { eq: $pageType } }
      first: 1
    ) {
      edges {
        node {
          id
          title
          description
          image
          content {
            ...HeroItem
            ...HighlightItem
            ...IntroItem
            ...ListItem
            ...LocationItem
            ...SliderItem
            ...ArticleItem
            ...QuoteItem
            ...UnknownItem
          }
        }
      }
    }
  }
  ${fragments.article}
  ${fragments.hero}
  ${fragments.highlight}
  ${fragments.quote}
  ${fragments.slider}
  ${fragments.image}
  ${fragments.intro}
  ${fragments.list}
  ${fragments.listItem}
  ${fragments.location}
  ${fragments.title}
  ${fragments.unknown}
  ${fragments.fileLink}
  ${fragments.internalLink}
  ${fragments.externalLink}
`;
