import React, { memo } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';

import theme from '../theme';
import { useStateContext } from '../lib/context';
import { useLocale } from '../lib/hooks';
import { MENU } from '../gql/menu';

import monogram from '../assets/monogram-thin.svg';

import Label from './Label';
import Link from './Link';
import LinkExternal from './LinkExternal';
import LinkEmail from './LinkEmail';
import SocialNav from './SocialNav';
import Wrap from './Wrap';
import Logo from '../components/Logo';

const RGB_COLOR = [27, 54, 93];

const StyledFooter = styled.footer`
  background-color: ${theme.colors.default};
  color: ${theme.colors.tertiary};
  font-family: ${theme.typography.families.title};
  font-size: ${theme.typography.font100};
  font-weight: ${theme.typography.weights.bold};
  position: relative;

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${theme.typography.font200};
  }

  &::before {
    background-image: linear-gradient(
      269deg,
      rgba(${RGB_COLOR.join(',')}, 0.1),
      transparent,
      rgba(${RGB_COLOR.join(',')}, 0.35)
    );
    bottom: 0;
    content: '';
    display: block;
    height: ${theme.sizing.scale500};
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::after {
    background-color: inherit;
    background-image: url(${monogram});
    background-position: 0 -2px;
    background-size: 25px auto;
    border-top: 1px solid ${theme.colors.quaternary};
    content: '';
    display: block;
    height: ${theme.sizing.scale500};
    margin-top: ${theme.sizing.scale200};
    width: 100%;
  }
`;

const StyledWrap = styled(Wrap)`
  display: grid;
  gap: ${theme.sizing.scale400};
  justify-items: center;
  padding-bottom: ${theme.sizing.scale500};
  padding-top: ${theme.sizing.scale500};
  text-align: center;

  @media (max-width: ${theme.breakpoints.small}) {
    padding-bottom: ${theme.sizing.scale300};
    row-gap: ${theme.sizing.scale300};
  }
`;

const Grid = styled.section`
  align-items: center;
  display: grid;
  column-gap: ${50 / theme.typography.baseFontSize}rem;
  grid-auto-flow: column;
  grid-template-columns: ${({ spacer }) =>
    spacer ? 'repeat(3, 1fr)' : 'auto'};
  justify-content: ${({ justify }) => justify};
  padding: ${({ spacer }) => (spacer ? `0 ${theme.sizing.scale400}` : '0')};
  row-gap: ${theme.sizing.scale200};

  @media (max-width: ${theme.breakpoints.small}) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    justify-items: center;
    order: ${({ order }) => (order ? order : 'auto')};
    row-gap: ${({ spacer }) =>
      spacer ? theme.sizing.scale300 : theme.sizing.scale100};
  }
`;

const SmallLink = styled(Label)`
  color: ${theme.colors.tertiary};
  justify-self: end;
  opacity: 0.6;
  transition: opacity ${theme.transitions.duration} ${theme.transitions.easing};

  @media (max-width: ${theme.breakpoints.small}) {
    justify-self: center;
  }

  &:hover {
    opacity: 1;
  }
`;

const Footer = () => {
  const [
    {
      settings: {
        complaintsLabel,
        complaintsUrl,
        emailLabel,
        email,
        privacyLabel,
        privacyUrl,
      },
    },
  ] = useStateContext();
  const locale = useLocale();
  const { loading, data, error } = useQuery(MENU, {
    variables: {
      locale,
      placement: 'Main',
    },
  });

  if (loading || error || !data || (data && !data.Menu)) {
    return null;
  }

  const { items } = data.Menu;

  return (
    <StyledFooter>
      <StyledWrap>
        <Logo />
        <SocialNav showLabel />
        {email && (
          <div>
            <Label small as="div">
              {emailLabel}
            </Label>
            <Label as={LinkEmail} href={email} small>
              {email}
            </Label>
          </div>
        )}
      </StyledWrap>

      <Grid justify="space-between" spacer>
        <Grid justify="start" as="nav">
          <SmallLink as={LinkExternal} href={privacyUrl}>
            {privacyLabel}
          </SmallLink>
          <SmallLink as={LinkExternal} href={complaintsUrl}>
            {complaintsLabel}
          </SmallLink>
        </Grid>
        <Grid justify="center" order="-1">
          {items &&
            items.map((item, i) => <Label {...item} as={Link} key={i} small />)}
        </Grid>
        <SmallLink as={LinkExternal} href="http://duallstudio.com">
          made by duall
        </SmallLink>
      </Grid>
    </StyledFooter>
  );
};

export default memo(Footer);
