import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { animated, config, useTrail } from 'react-spring';

import theme from '../theme';

import { useIsDesktop } from '../lib/hooks';

const { fadeUpSmall } = theme.animations;

export function getWords(content, isDesktop) {
  return isDesktop
    ? content.match(/\S+(?:(\s*))\S+(?:(\s*))\S+(?:(\s*))/g)
    : [content];
}

export const WordGroup = ({ active, content }) => {
  const isDesktop = useIsDesktop();
  const words = getWords(content, isDesktop);
  const trail = useTrail(words.length, {
    from: fadeUpSmall.from,
    to: active ? fadeUpSmall.to : fadeUpSmall.from,
    config: config.gentle
  });

  return trail.map((style, i) => (
    <Word key={i} style={style}>
      {words[i]}
    </Word>
  ));
};

WordGroup.propTypes = {
  active: PropTypes.bool,
  content: PropTypes.string.isRequired
};

const Word = styled(animated.span)`
  display: ${({ $alt }) => ($alt ? 'inline' : 'inline-block')};
  white-space: pre-wrap;
`;

export default Word;
