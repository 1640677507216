import React, { forwardRef, memo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';

import { useAnimationFrame, useIntersectionObserver } from '../lib/hooks';
import { removeProtocol } from '../lib/page';

const StyledFigure = styled(animated.figure)`
  display: block;
  overflow: hidden;
  position: relative;

  &.clip {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0);

    &::before {
      content: '';
      display: block;
      padding-bottom: 85%;
      width: 100%;
    }
  }
`;

const StyledImg = styled(animated.img)`
  display: block;
  margin: 0;
  max-width: 100%;
  object-fit: cover;
  object-position: center;
  user-select: none;

  @media (max-width: ${theme.breakpoints.small}) {
    width: 100%;
  }

  .cover > & {
    height: 100%;
    width: 100%;
  }

  .clip > & {
    height: 80vh;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 80vh;
    will-change: transform;

    @media (max-width: ${theme.breakpoints.small}) {
      height: 100%;
      width: 100%;
    }
  }
`;

const { fadeIn } = theme.animations;

const Figure = (
  {
    alt = '',
    animate = true,
    className,
    clip = false,
    cover = false,
    image,
    onRest = () => {},
    ...rest
  },
  ref
) => {
  const [translateY, setScrolled] = useState(0);
  const figureRef = useRef();
  const itemRef = ref ? ref : figureRef;
  const active = useIntersectionObserver(itemRef, 0.1);
  const animFigure = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.molasses,
    onRest
  });

  useAnimationFrame(() => {
    const scrolled =
      itemRef && itemRef.current
        ? itemRef.current.getBoundingClientRect().top
        : 0;

    setScrolled((scrolled - window.innerHeight) / 6);
  });

  return (
    <StyledFigure
      ref={itemRef}
      style={animate ? animFigure : {}}
      className={`${className ? className : ''} ${clip && 'clip'} ${cover &&
        'cover'}`}
      {...rest}
    >
      <StyledImg
        src={removeProtocol(image)}
        style={
          clip &&
          itemRef.current &&
          window.innerWidth > parseInt(theme.breakpoints.small)
            ? {
                transform: `translateY(${translateY}px)`
              }
            : {}
        }
        {...rest}
      />
    </StyledFigure>
  );
};

export default memo(forwardRef(Figure));
