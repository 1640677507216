import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';

import { useIntersectionObserver } from '../lib/hooks';

const StyledLabel = styled(animated.h3)`
  color: ${({ $light, $small }) =>
    $small ? 'inherit' : $light ? theme.colors.quinary : theme.colors.primary};
  font-family: ${theme.typography.families.title};
  font-size: ${({ $small }) =>
    $small ? theme.typography.font100 : theme.typography.font200};
  font-weight: ${theme.typography.weights.bold};
  letter-spacing: ${({ $small }) =>
    ($small ? 1 : 5) / theme.typography.baseFontSize}rem;
  text-transform: uppercase;

  @media (max-width: ${theme.breakpoints.small}) {
    font-size: ${theme.typography.font200};
  }

  &[href] {
    transition: color ${theme.transitions.duration} ${theme.transitions.easing};

    &:hover {
      color: ${theme.colors.primary};
    }
  }
`;

const { fadeUp } = theme.animations;

const Label = ({ children, light = false, small = false, ...rest }) => {
  const ref = useRef();
  const active = useIntersectionObserver(ref);
  const anim = useSpring({
    from: fadeUp.from,
    to: active ? fadeUp.to : fadeUp.from,
    config: config.slow
  });

  return (
    <StyledLabel $light={light} $small={small} ref={ref} style={anim} {...rest}>
      {children}
    </StyledLabel>
  );
};

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  light: PropTypes.bool,
  small: PropTypes.bool
};

export default memo(Label);
