import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import theme from '../theme';

import Paragraph from '../components/Paragraph';
import Title from '../components/Title';

const Main = styled.main`
  align-content: center;
  background-color: ${theme.colors.default};
  color: #fff;
  height: 100vh;
  display: grid;
  flex-direction: column;
  justify-items: center;
  padding: ${theme.sizing.scale400};
  row-gap: ${theme.sizing.scale300};
`;

export default () => {
  return (
    <Main>
      <Helmet>
        <title>Error</title>
      </Helmet>
      <Title>Oops</Title>
      <Paragraph content="An error occurred, please try again or contact us." />
    </Main>
  );
};
