import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSpring, animated, config } from 'react-spring';

import theme from '../theme';
import { useIntersectionObserver } from '../lib/hooks';

import { ReactComponent as Arrow } from '../assets/arrow.svg';

import Figure from './Figure';
import Wrap from './Wrap';
import { ArticleWrap } from './Article';
import { IntroWrap } from './Intro';
import { HeroWrap } from './Hero';
import ButtonCircle from './ButtonCircle';
import { BUTTON_GRADIENT } from './Button';

const SLIDE_MARGIN = '100px';
const SLIDE_MARGIN_ABSOLUTE = parseInt(SLIDE_MARGIN);

const SliderWrap = styled(animated(Wrap))`
  position: relative;

  @media (max-width: ${theme.breakpoints.medium}) {
    padding-left: 0;
    padding-right: 0;

    ${ArticleWrap} + &,
    ${IntroWrap} + &  {
      padding-top: 0;
    }
  }

  ${HeroWrap} + & {
    padding-top: ${theme.sizing.scale700};

    @media (max-width: ${theme.breakpoints.small}) {
      padding-top: ${theme.sizing.scale500};
    }
  }

  & + ${IntroWrap} {
    margin-top: ${theme.sizing.scale700};

    @media (max-width: ${theme.breakpoints.small}) {
      margin-top: 0;
    }
  }
`;

const SliderItems = styled.section`
  -webkit-overflow-scrolling: touch;
  cursor: grab;
  display: flex;
  position: relative;
  touch-action: pan-x;
  transition: transform ${theme.transitions.durationMedium}
    ${theme.transitions.easingAlt};
`;

const SliderItem = styled(Figure)`
  max-height: 550px;
  margin-right: ${({ clip }) => (clip ? 0 : SLIDE_MARGIN)};
  flex-shrink: 0;
  left: 0;
  opacity: ${({ $active, clip }) => ($active || !clip ? 1 : 0)};
  pointer-events: ${({ clip }) => (clip ? 'none' : 'all')};
  position: ${({ $active, clip }) =>
    $active || !clip ? 'relative' : 'absolute'};
  top: 0;
  transition: opacity ${theme.transitions.durationMedium}
    ${theme.transitions.easingAlt};
  width: 100%;
`;

const Button = styled(animated(ButtonCircle))`
  left: ${({ $prev }) =>
    $prev ? `-${SLIDE_MARGIN_ABSOLUTE / 2 - 10}px` : '100%'};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all ${theme.transitions.duration} ${theme.transitions.easing};
  z-index: 10;

  svg {
    background: ${BUTTON_GRADIENT};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: ${({ $prev }) => ($prev ? 'scaleX(-1)' : 'none')};
    transition: all ${theme.transitions.duration} ${theme.transitions.easing};
  }

  &[disabled] {
    border-color: ${theme.colors.quinary};
    color: ${theme.colors.quinary};
    opacity: 0.5;
    pointer-events: none;
  }

  @media (max-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const SliderNav = styled(animated.div)`
  display: none;
  justify-content: center;
  margin-top: ${theme.sizing.scale400};

  @media (max-width: ${theme.breakpoints.medium}) {
    display: flex;
    width: 100%;
  }
`;

const SliderButton = styled.button`
  background-color: #0a1627;
  border-radius: 50%;
  height: 7px;
  margin: 0 5px;
  transition: background-color ${theme.transitions.duration}
    ${theme.transitions.easing};
  width: 7px;

  &:hover,
  &:focus,
  &.active {
    background-color: ${theme.colors.primary};
  }
`;

const SliderArrow = props => (
  <Button {...props}>
    <Arrow />
  </Button>
);

const { fadeIn } = theme.animations;

const Slider = ({ alt = false, className, items = [] }) => {
  const sliderRef = useRef(null);
  const slideRef = useRef(null);
  const active = useIntersectionObserver(sliderRef);
  const [activeSlide, setActiveSlide] = useState(0);
  const [itemWidth, setItemWidth] = useState(0);
  const [canMove, setCanMove] = useState(false);
  const [initialPos, setInitialPos] = useState(0);

  const animContent = useSpring({
    from: fadeIn.from,
    to: active ? fadeIn.to : fadeIn.from,
    config: config.molasses
  });

  const getCurrentSlide = pos => {
    const offset = 10;

    if (pos > initialPos + offset) {
      return Math.max(activeSlide - 1, 0);
    }

    if (pos < initialPos - offset) {
      return Math.min(activeSlide + 1, items.length - 1);
    }

    return activeSlide;
  };

  const updateSlider = pos => {
    const active = getCurrentSlide(pos);

    if (active !== activeSlide && canMove) {
      setActiveSlide(active);
      setCanMove(false);
    }
  };

  const getWidth = () => {
    if (slideRef.current) {
      setItemWidth(slideRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', getWidth, true);

    return () => window.removeEventListener('resize', getWidth, true);
  }, [slideRef]);

  return items ? (
    <SliderWrap ref={sliderRef} className={className} style={animContent} large>
      <SliderArrow
        onClick={() => setActiveSlide(activeSlide - 1)}
        disabled={activeSlide <= 0}
        $prev
      />
      <SliderItems
        onTouchStart={e => {
          const touch = e.touches || e.changedTouches;
          setInitialPos(touch[0].clientX);
          setCanMove(true);
        }}
        onTouchMove={e => {
          const touch = e.touches || e.changedTouches;
          updateSlider(touch[0].clientX);
        }}
        onTouchEnd={() => setCanMove(false)}
        onMouseDown={e => {
          e.preventDefault();

          setInitialPos(e.clientX);
          setCanMove(true);
        }}
        onMouseMove={e => updateSlider(e.clientX)}
        onMouseUp={() => setCanMove(false)}
        style={
          alt
            ? null
            : {
                transform: `translateX(-${(itemWidth + SLIDE_MARGIN_ABSOLUTE) *
                  activeSlide}px)`
              }
        }
      >
        {items.map((image, i) => (
          <SliderItem
            key={i}
            ref={slideRef}
            onRest={() => getWidth()}
            cover
            animate={false}
            clip={alt}
            $active={i === activeSlide}
            {...image}
          />
        ))}
      </SliderItems>
      <SliderArrow
        onClick={() => setActiveSlide(activeSlide + 1)}
        disabled={activeSlide >= items.length - 1}
      />
      <SliderNav aria-hidden="true">
        {items.map((_, i) => (
          <SliderButton
            key={i}
            className={activeSlide === i ? 'active' : ''}
            onClick={() => setActiveSlide(i)}
          ></SliderButton>
        ))}
      </SliderNav>
    </SliderWrap>
  ) : null;
};

Slider.propTypes = {
  alt: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape(
      {
        image: PropTypes.string,
        label: PropTypes.string
      }.isRequired
    )
  )
};

export default Slider;
