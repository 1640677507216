import gql from 'graphql-tag';

const fragmentsLinks = {
  fileLink: gql`
    fragment FileLink on FileLink {
      title
      url
    }
  `,
  internalLink: gql`
    fragment InternalLink on PageLink {
      title
      page(locale: $locale) {
        pageType
        title
        slug
      }
    }
  `,
  externalLink: gql`
    fragment ExternalLink on ExternalLink {
      title
      url
    }
  `,
  unknown: gql`
    fragment UnknownItem on Prime_UnknownSlice {
      error
      raw
    }
  `
};

export default {
  article: gql`
    fragment ArticleItem on Article {
      layout
      label
      subTitle
      title
      text
      image
      link: button {
        ...FileLink
        ...InternalLink
        ...ExternalLink
        ...UnknownItem
      }
    }
  `,
  hero: gql`
    fragment HeroItem on Hero {
      title
      label
      image
      size
      text
      button {
        ...FileLink
        ...InternalLink
        ...ExternalLink
        ...UnknownItem
      }
    }
  `,
  highlight: gql`
    fragment HighlightItem on Highlight {
      title
      label
      text
      images {
        image
        alt
      }
      button {
        ...FileLink
        ...InternalLink
        ...ExternalLink
        ...UnknownItem
      }
    }
  `,
  image: gql`
    fragment Image on Image {
      image
      alt
    }
  `,
  intro: gql`
    fragment IntroItem on Intro {
      type
      text
    }
  `,
  list: gql`
    fragment ListItem on List {
      items {
        ...ListDetail
        ...Image
        ...Title
      }
    }
  `,
  listItem: gql`
    fragment ListDetail on ListItem {
      title
      text
    }
  `,
  location: gql`
    fragment LocationItem on Location {
      title
      subTitle
      label
      address
      text
    }
  `,
  quote: gql`
    fragment QuoteItem on Quote {
      text
    }
  `,
  slider: gql`
    fragment SliderItem on Slider {
      items {
        image
        alt
      }
    }
  `,
  title: gql`
    fragment Title on Title {
      title
    }
  `,
  ...fragmentsLinks
};
