import styled from 'styled-components';
import { animated } from 'react-spring';

import theme from '../theme';

const BORDER_WIDTH = '1.5px';
const ANGLE_SIZE = '15px';

export const BUTTON_GRADIENT = `linear-gradient(
      221deg,
      ${theme.colors.quaternary},
      ${theme.colors.primary}
    )`;

const Button = styled(animated.button)`
  align-items: center;
  background-color: transparent;
  color: inherit;
  display: inline-flex;
  font-family: ${theme.typography.families.title};
  font-size: ${theme.typography.font400};
  font-weight: ${theme.typography.weights.bold};
  justify-content: center;
  letter-spacing: ${1 / theme.typography.baseFontSize}rem;
  min-width: 185px;
  padding: ${theme.sizing.scale100} ${theme.sizing.scale300};
  position: relative;
  text-align: center;
  text-transform: lowercase;
  transform-style: preserve-3d;
  transition: color ${theme.transitions.duration} ${theme.transitions.easing};

  @media (max-width: ${theme.breakpoints.small}) {
    letter-spacing: 0;
  }

  &:before,
  &::after {
    background: ${BUTTON_GRADIENT};
    clip-path: polygon(
      0 50%,
      ${ANGLE_SIZE} 0,
      calc(100% - ${ANGLE_SIZE}) 0,
      100% 50%,
      calc(100% - ${ANGLE_SIZE}) 100%,
      ${ANGLE_SIZE} 100%
    );
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transform: translateZ(-1px);
    transition: opacity ${theme.transitions.duration}
      ${theme.transitions.easing};
    width: 100%;
  }

  &::after {
    clip-path: polygon(
      0 50%,
      ${ANGLE_SIZE} 100%,
      calc(100% - ${ANGLE_SIZE}) 100%,
      100% 50%,
      calc(100% - ${ANGLE_SIZE}) 0%,
      ${ANGLE_SIZE} 0%,
      0 50%,
      ${BORDER_WIDTH} 50%,
      calc(${ANGLE_SIZE} + ${BORDER_WIDTH}) ${BORDER_WIDTH},
      calc(100% - ${ANGLE_SIZE} - ${BORDER_WIDTH}) ${BORDER_WIDTH},
      calc(100% - ${BORDER_WIDTH}) 50%,
      calc(100% - ${ANGLE_SIZE} - ${BORDER_WIDTH}) calc(100% - ${BORDER_WIDTH}),
      calc(${ANGLE_SIZE} + ${BORDER_WIDTH}) calc(100% - ${BORDER_WIDTH}),
      ${BORDER_WIDTH} 50%
    );
    opacity: 1;
  }

  &:hover,
  &:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    color: #fff;
    outline: 0;

    &::before {
      opacity: 1;
    }
  }
`;

export default Button;
