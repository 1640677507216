import brandonFont from '../assets/BrandonGrotesque-Bold.woff';
import caslonFont from '../assets/ACaslonPro-Regular.woff';
import caslonBoldFont from '../assets/ACaslonPro-Bold.woff';

const fontWeights = {
  light: 300,
  regular: 400,
  bold: 700
};

export const fontFaces = [
  {
    name: 'Brandon Grotesque',
    location: brandonFont,
    weight: fontWeights.bold,
    fallback: 'sans-serif'
  },
  {
    name: 'Adobe Caslon Pro',
    location: caslonFont,
    weight: fontWeights.regular,
    fallback: 'serif'
  },
  {
    name: 'Adobe Caslon Pro',
    location: caslonBoldFont,
    weight: fontWeights.bold,
    fallback: 'serif'
  }
];

const typographyDefaults = {
  baseFontSize: 18,
  baseLineHeight: 1.5,
  weights: fontWeights,
  families: {
    title: `${fontFaces[0].name}, ${fontFaces[0].fallback}`,
    body: `${fontFaces[1].name}, ${fontFaces[1].fallback}`
  }
};

const sizing = {
  scale100: `${10 / typographyDefaults.baseFontSize}rem`,
  scale200: `${20 / typographyDefaults.baseFontSize}rem`,
  scale300: `${30 / typographyDefaults.baseFontSize}rem`,
  scale400: `${40 / typographyDefaults.baseFontSize}rem`,
  scale500: `${60 / typographyDefaults.baseFontSize}rem`,
  scale600: `${100 / typographyDefaults.baseFontSize}rem`,
  scale700: `${150 / typographyDefaults.baseFontSize}rem`
};

export default {
  breakpoints: {
    large: '1250px',
    medium: '1024px',
    small: '900px',
    xsmall: '350px'
  },
  colors: {
    default: '#1b365d',
    defaultFaded: '#5d6a7e',
    primary: '#9b8248',
    secondary: '#E3E2DB',
    tertiary: '#f8f5ec',
    quaternary: '#cbb87e',
    quinary: '#d2c7aa'
  },
  sizing,
  transitions: {
    duration: '.35s',
    durationMedium: '.5s',
    durationLong: '.75s',
    easing: 'cubic-bezier(0.445, 0.050, 0.550, 0.950)' /* easeInOutSine */,
    easingAlt: 'cubic-bezier(0.470, 0.000, 0.745, 0.715)' /* easeInSine */,
    easingBack: 'cubic-bezier(0.175, 0.885, 0.320, 1.275)' /* easeOutBack */
  },
  typography: {
    ...typographyDefaults,
    font100: `${10 / typographyDefaults.baseFontSize}rem`,
    font200: `${12 / typographyDefaults.baseFontSize}rem`,
    font300: `${13 / typographyDefaults.baseFontSize}rem`,
    font400: '1rem',
    font500: `${22 / typographyDefaults.baseFontSize}rem`,
    font600: `${26 / typographyDefaults.baseFontSize}rem`,
    font700: `${36 / typographyDefaults.baseFontSize}rem`,
    font800: `${45 / typographyDefaults.baseFontSize}rem`,
    font900: `${65 / typographyDefaults.baseFontSize}rem`
  },
  animations: {
    fadeIn: {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },
    fadeUp: {
      from: {
        opacity: 0,
        transform: `translateY(${sizing.scale200})`
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    fadeUpSmall: {
      from: {
        opacity: 0,
        transform: 'translateY(3%)'
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)'
      }
    },
    scaleDown: {
      from: {
        opacity: 0,
        transform: `scale(1.2)`
      },
      to: {
        opacity: 1,
        transform: 'scale(1)'
      }
    }
  }
};
