import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';
import ButtonCircle from './ButtonCircle';
import { BUTTON_GRADIENT } from './Button';

const StyledButtonCircle = styled(ButtonCircle)``;

const ButtonIcon = styled.span`
  display: block;
  width: 60%;

  &::before,
  &::after {
    background: ${BUTTON_GRADIENT};
    content: '';
    display: block;
    height: 1px;
    margin: 4px auto;
    transition: background ${theme.transitions.duration}
      ${theme.transitions.easing};
    width: 100%;
  }

  ${StyledButtonCircle}:hover &,
  ${StyledButtonCircle}:focus & {
    &::before,
    &::after {
      background: currentColor;
    }
  }

  [aria-expanded='true'] & {
    &::before,
    &::after {
      transform: translate(0, 220%) rotate(45deg) scaleX(0.5);
    }

    &::after {
      transform: translate(0, -280%) rotate(-45deg) scaleX(0.5);
    }
  }
`;

const Burger = ({ onClick, open }) => (
  <StyledButtonCircle
    aria-expanded={open}
    onClick={() => {
      onClick();
    }}
  >
    <ButtonIcon />
  </StyledButtonCircle>
);

Burger.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool
};

export default memo(Burger);
