import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

import Figure from './Figure';
import Title from './Title';
import Paragraph from './Paragraph';
import Wrap from './Wrap';

const Section = styled(Wrap)`
  align-items: start;
  column-gap: ${theme.sizing.scale300};
  counter-reset: item;
  display: grid;
  justify-content: center;
  max-width: 1200px;
  row-gap: ${theme.sizing.scale500};
  text-align: center;

  @media (min-width: ${theme.breakpoints.large}) {
    column-gap: ${theme.sizing.scale500};
  }

  @media (min-width: ${theme.breakpoints.small}) {
    grid-template-columns: repeat(10, 1fr);
    text-align: inherit;
  }
`;

const ListTitle = styled(Title)`
  max-width: 640px;

  @media (min-width: ${theme.breakpoints.small}) {
    grid-column: 3 / -1;
  }

  &:first-child {
    font-size: ${76 / theme.typography.baseFontSize}rem;

    @media (max-width: ${theme.breakpoints.medium}) {
      font-size: ${40 / theme.typography.baseFontSize}rem;
      line-height: 1.12;
    }
  }
`;

const ListImage = styled(Figure)`
  justify-self: center;
  max-width: 300px;
  width: 100%;

  @media (max-width: ${theme.breakpoints.small}) {
    &:nth-of-type(3) {
      display: none;
    }
  }

  @media (min-width: ${theme.breakpoints.small}) {
    align-self: center;
    grid-column: span 5;
    max-width: 100%;

    &:first-of-type {
      transform: translateY(10%);
    }

    &:nth-of-type(3) {
      transform: translateY(-27%);
    }

    &:nth-of-type(4) {
      grid-column: 5 / 10;
    }

    &:nth-of-type(4n + 2) {
      width: 60%;
    }
  }
`;

const Item = styled.article`
  align-content: start;
  counter-increment: item;
  display: grid;
  row-gap: ${theme.sizing.scale100};

  @media (min-width: ${theme.breakpoints.small}) {
    grid-column: span 2;
    row-gap: ${theme.sizing.scale200};

    &:nth-of-type(3) {
      grid-column: 4 / 6;
      transform: translateY(-${theme.sizing.scale400});
    }

    &:nth-of-type(4) {
      transform: translateY(-${theme.sizing.scale400});
    }

    &:nth-of-type(5) {
      align-self: center;
      grid-column: 2 / 4;
    }

    &:nth-of-type(6) {
      align-self: center;
      grid-column: 7 / 9;
    }
  }
`;

const ItemTitle = styled(Title)`
  font-size: ${24 / theme.typography.baseFontSize}rem;
  line-height: 1;
  opacity: 0;
  transition-duration: ${theme.transitions.durationMedium};
  transition-timing-function: ${theme.transitions.easingAlt};

  &::before {
    color: ${theme.colors.primary};
    content: counter(item, decimal-leading-zero);
    display: block;
    font-family: ${theme.typography.families.title};
    font-size: ${theme.typography.font200};
    letter-spacing: ${5 / theme.typography.baseFontSize}rem;
    padding-bottom: 0.7rem;

    @media (min-width: ${theme.breakpoints.small}) {
      padding-bottom: 0.5rem;
    }
  }

  &.active {
    opacity: 1;
  }

  * {
    display: inline;
    transition: none;
  }
`;

const ListItem = ({ title, text }) => {
  return (
    <Item>
      <ItemTitle size="small">{title}</ItemTitle>
      <Paragraph content={text} />
    </Item>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

const List = ({ items }) => {
  return (
    <Section spacer>
      {items &&
        items.map((item, i) => {
          switch (item.__typename) {
            case 'Title':
              return (
                <ListTitle size="medium" key={i}>
                  {item.title}
                </ListTitle>
              );
            case 'ListItem':
              return <ListItem key={i} {...item} />;
            case 'Image':
              return <ListImage key={i} {...item} clip />;
            default:
              return null;
          }
        })}
    </Section>
  );
};

List.propTypes = {
  items: PropTypes.array
};

export default memo(List);
