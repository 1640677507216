export const pageTypes = {
  default: 'Page',
  home: 'Home',
};

export const getPageType = slug => {
  if (slug) {
    return pageTypes.default;
  }

  return pageTypes.home;
};

export const isHome = pageType => {
  if (pageType === pageTypes.home) {
    return true;
  }

  return false;
};

export const removeProtocol = url => {
  if (url) {
    return url.replace(/(^\w+:|^)\/\//, '//');
  }

  return null;
};
